import { Injectable } from '@angular/core';
import { DialogMessage } from '../models/dialog-message';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogMessageType } from '../models/dialog-message-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageComponent } from '../components/message/message.component';
import { ResponseError } from '../models/response-error';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DialogMessageService {
  private _messages: Subject<DialogMessage>;

  public get messages$(): Observable<DialogMessage> {
    return this._messages.asObservable();
  }

  constructor(private readonly _dialog: MatDialog, private translate: TranslateService) {
    this._messages = new Subject<DialogMessage>();
    this._initialize();
  }

  public _initialize() {
    this.messages$.subscribe((x) => {
      this._dialog.open(MessageComponent, {
        panelClass: 'mat-dialog-container-dialog-message',
        disableClose: true,
        autoFocus: false,
        position: {
          top: '50px'
        },
        data: x,
      });
    });
  }

  public showMessage(title: string, message: string) {
    this.show(title, message, DialogMessageType.INFO);
  }

  public showWarning(title: string, message: string) {
    this.show(title, message, DialogMessageType.WARNING);
  }

  public showQuestion(title: string, message: string) {
    let msg = new DialogMessage();
    msg.title = title;
    msg.message = message;
    msg.type = DialogMessageType.QUESTION;

    let a = this._dialog.open(MessageComponent, {
      panelClass: 'mat-dialog-container-dialog-message',
      disableClose: true,
      autoFocus: false,
      position: {
        top: '50px'
      },
      data: msg,
    });

    return a.afterClosed()
      .pipe(
        map(x => <boolean>x)
      );
  }

  public showConfirmDelete() {
    return this.showQuestion("Conferma eliminazione", "Sei sicuro di voler continuare?");
  }

  public showConfirmLogout() {
    return this.showQuestion("Conferma logout", "Sei sicuro di voler effettuare il logout?");
  }

  public showConfirm(title: string, message: string) {
    return this.showQuestion(title, message);
  }

  public showError(message: string) {
    this.show(this.translate.instant("TITLE"), message, DialogMessageType.ERROR);
  }

  public showResponseError(responseError: ResponseError) {
    let msg = new DialogMessage();
    msg.title = this.translate.instant("TITLE");
    msg.message = responseError.message;
    msg.type = DialogMessageType.ERROR;
    msg.url = responseError.url;
    msg.status = responseError.status;
    msg.details = responseError.stacktrace;
    this._show(msg);
  }

  public show(title: string, message: string, type: DialogMessageType) {
    let msg = new DialogMessage();
    msg.title = title;
    msg.message = message;
    msg.type = type;
    this._show(msg);
  }

  private _show(message: DialogMessage) {
    this._messages.next(message);
  }

  public showUsernameAlreadyExistsError(username: string) {
    this.translate.get('USER.ERRORS_DUPLICATE_USERNAME', { username }).subscribe((translatedMessage: string) => {
      this.showError(translatedMessage);
    });
  }

  public showFileSizeError(message: string) {
    this.show(this.translate.instant("ERROR_MESSAGE.TITLE"), message, DialogMessageType.ERROR);
  }
}
